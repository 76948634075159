<template>
  <v-container fluid>
    <v-divider> </v-divider>
    <h2>Gallery Selection</h2>

    <v-row dense>
      <v-col v-for="gallery in galleries" :key="gallery.id" cols="2">
        <v-card>
          <v-card-actions>
            <v-btn
              color="success"
              text
              @click="getPhotos(gallery.id, gallery.description)"
              >{{ gallery.description }}
            </v-btn>
          </v-card-actions>

          <img
            :src="getImgUrl(gallery.name)"
            max-height="100"
            max-width="150"
          />
        </v-card>
      </v-col>
    </v-row>

    <h1>{{ this.galleryname }}</h1>
    <v-divider>:inset="Yes"</v-divider>
    <div class="text-center">
      <v-pagination
        v-model="thispage"
        :length="maxpage"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="getnewpage"
      >
      </v-pagination>
    </div>

    <v-row dense>
      <v-col v-for="photo in photos" :key="photo.id" cols="2">
        <v-card>
          <v-toolbar flat color="light-blue accent-4" dark>
            <v-btn icon>
              <v-icon>mdi-check-outline </v-icon>
            </v-btn>
            <v-toolbar-title>{{ photo.name }}</v-toolbar-title>
          </v-toolbar>

          <v-img :src="getImgUrl(photo.name)" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      galleries: [],
      photos: [],
      displayphotos: [],
      keywords: [],
      maxphotos: 0,
      maxpage: 1,
      maxperpage: 12,
      thispage: 1,
      dialog: false,
      startpicture: 0,
      endpicture: 0,
      startpos: 0,

      galleryname: ""
    };
  },
  mounted() {
    this.galleries = this.$store.getters.allgalleries;
    this.photos = [];
    this.keywords = this.$store.getters.allkeywords;
  },

  methods: {
    getPhotos(galleryid, description) {
      this.thispage = 1;
      if (galleryid === 999) {
        this.galleries = this.$store.getters.allgalleries;
        this.displayphotos = this.$store.getters.allphotos;
      } else {
        this.displayphotos = this.$store.getters.photosbygalleryID(galleryid);
      }
      this.getnewpage();

      this.maxpage = Math.ceil(this.displayphotos.length / 12);

      if (description === "All") {
        this.galleryname = "Pictures of all galleries are below";
      } else {
        this.galleryname = "Pictures of " + description + " are below";
      }
    },
    getnewpage() {
      if (this.thispage == 1) {
        this.startpos = 0;
      } else {
        this.startpos = (this.thispage - 1) * 12;
      }

      this.photos = this.displayphotos.slice(this.startpos, this.startpos + 12);
    },
    getkeywords(keywordid) {
      switch (keywordid) {
        case 0:
          this.photos = this.$store.getters.photosbymountain;
          break;
        case 1:
          this.photos = this.$store.getters.photosbywater;
          break;
        case 2:
          this.photos = this.$store.getters.photosbycity;
          break;
        case 3:
          this.photos = this.$store.getters.photosbysunrise;
          break;
        case 4:
          this.photos = this.$store.getters.photosbysunset;
          break;
        case 5:
          this.photos = this.$store.getters.photosbyreflection;
          break;
        default:
      }
    },
    getImgUrl(landscape) {
      var images = require.context("../assets/", false, /\.jpg$/);
      return images("./" + landscape + ".jpg");
    },
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    }
  }
};
</script>
