<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="blog in blogs" :key="blog.id" cols="12">
        <template v-if="blog.blogcommand === 'text'">
          {{ blog.blogtext }}
        </template>

        <template v-if="blog.blogcommand === 'header'">
          <h1>
            {{ blog.blogtext }}
          </h1>
        </template>

        <template v-if="blog.blogcommand === 'picture'">
          <img
            src="getImgURL(blog.blogtext)"
            max-height="200"
            max-width="300"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: function() {
    return {
      blogs: []
    };
  },
  mounted() {
    this.blogs = this.$store.getters.allblogs;
  },
  methods: {
    getImgUrl(landscape) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + landscape + ".jpg");
    }
  }
};
</script>
